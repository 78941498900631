import React from 'react';
import { st, classes } from './Sidebar.st.css';
import { MainComponents, SectionTypes } from '../../types';
import { RenderLocationProvider } from '../useRenderLocation';
import settingsParams from '../../settingsParams';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import getSidebarAriaLabel from './getSidebarAriaLabel';
import { useTranslation, useEnvironment } from '@wix/yoshi-flow-editor';

type SidebarProps = {
  className?: string;
  sectionType: SectionTypes;
};

const SidebarComponent: React.FC<SidebarProps> = ({
  className,
  sectionType,
  children,
}) => {
  const { t } = useTranslation();
  const settings = useSettings();
  const { isMobile } = useEnvironment();

  return (
    <RenderLocationProvider value={MainComponents.SIDEBAR}>
      <aside
        role="region"
        aria-label={getSidebarAriaLabel(t, sectionType)}
        data-hook="sidebar"
        className={st(
          classes.root,
          {
            sticky: settings.get(settingsParams.sidebarFreezePosition),
            isMobile,
          },
          className,
        )}
      >
        {children}
      </aside>
    </RenderLocationProvider>
  );
};

export default SidebarComponent;
